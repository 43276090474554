<template>
  <section id="home" class="iq-main-slider p-0 iq-rtl-direction">
    <div class="hideslide"> 
    <div class="slide slick-bg s-bg-1 test"
      :style="'background: url(' + data.image + ');'">
      <b-container fluid class="position-relative h-100">
        <div class="slider-inner h-100">
          <b-row class="align-items-center  h-100 iq-ltr-direction">
            <b-col xl="6" lg="12" md="12">
              <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft" data-delay-in="0.6">
                {{ data.title }}</h1>
              <p data-animation-in="fadeInUp" data-delay-in="1.2">{{ data.description?.[$i18n.locale] }}</p>
              <div  data-animation-in="fadeInUp" data-delay-in="1.2">
                <a href="#detail" class="btn btn-hover viewMoreBtn aa" id="viewMoreBtn">
                  
                <div 
v-if="$i18n.locale == 'en'"> View More </div>
                <div v-if="$i18n.locale == 'ku'">  زیاتر نيشان بده </div>
                <div v-if="$i18n.locale == 'ar'">   عرض المزيد </div>
                
                
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
  </section>
</template>




<style scoped>

  .slick-bg {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    


    
    @media (max-width: 768px) {
      background-size:cover;
      width:10%;
      height:10% !important;
    }
  }
  
 
  
  .big-title {
    background: white;
    background-repeat: repeat-x;
    background-position: 100% 100%;
    color: transparent;
    -webkit-font-smoothing: antialiased;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 5px;
    padding-top: 35px;
    background-size: contain !important;


}
  
  
 
 .test
 {
  width:100%; 
  background-size:100% 100%;
  background-position: center center; 
  background-repeat: no-repeat;
   height: 100vh; position: 
   relative; z-index: 1;
   
   @media (min-width: 200px) and (max-width: 787px) {
    padding-left: 10px;
    }
   
   
   
   
 }
 
 .aa
 {
  
  background-color: aquamarine !important;
 }
 
 
</style>














<script>
import ApiService from "../../../../services/api"

export default {
  name: 'Home',
  components: {
  },
  mounted () {
    ApiService.getSetting().then((response) => {
      this.data = response.data.data
    })
  },
  data () {
    return {
      slideData: { title: 'Game Expert', age: '16', sesson: '2h 40m', text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.", src: require('@/assets/images/logo-transparent.png') },
      data: {}
    }
  }
}
</script>
